import React from 'react';
import 'whatwg-fetch'
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import Service from "../lib/Service";
import PropTypes from "prop-types";

class UserProfile extends React.Component {
    changeLanguage(lng) {
        this.props.i18n.changeLanguage(lng);
        const loggedInUser = this.props.authReducer.user;
        Service.updateUserLang(loggedInUser, lng);
    };

    render() {
        const t = this.props.t;
        return (
            <div>
                <h1>
                    {t('profile:User profile')}
                </h1>
                <ButtonGroup aria-label="Language" id={'lang-btn-group'}>
                    <Button variant="success" onClick={() => this.changeLanguage("en-US")}>EN</Button>
                    <Button variant="success" onClick={() => this.changeLanguage("ro")}>RO</Button>
                    <Button variant="success" onClick={() => this.changeLanguage("de")}>DE</Button>
                </ButtonGroup>
            </div>
        );
    }
}

UserProfile.propTypes = {
    user: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        authReducer: state.authReducer
    }
};

export default connect(mapStateToProps)(withTranslation()(UserProfile))
