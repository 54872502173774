import React from 'react';
import {PersonsList} from '../Person/List'
import Loader from '../components/Loader'
import Button from "react-bootstrap/Button";
import {Row, Col, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import {withTranslation} from 'react-i18next';
import Service from "../lib/Service";
import './PersonsList.css'

class Persons extends React.Component {
    _isMounted = false;

    state = {
        persons: [],
        displayPersons: [],
        filterValue: '',
        isFetching: false,
        fetchFailed: false,
        error: ''
    };

    componentWillUnmount() {
        this.setState({
            baZiMonthAnalysis: {},
            baZiFetching: false,
            psAnalysis: {},
            psFetching: false,
            fetchFailed: false
        });
        this._isMounted = false;
    }

    componentDidMount() {
        const t = this.props.t;
        this._isMounted = true;
        const userId = localStorage.getItem('loggedUser');

        if (userId === null) {
            this.setState({error: t('persons list:Err not logged in')});
            return;
        }
        this.setState({error: ''});

        let reqBody = {
            'analystId': userId
        };
        let params = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(reqBody)
        };
        this.setState({isFetching: true});
        fetch(`${Service.server}/api/portfolio`, params)
            .then(response => {
                console.info('Portfolio request successful');
                return response.json();
            })
            .catch(error => {
                this.setState({
                    fetchFailed: true,
                    isFetching: false,
                    error: t('persons list:Err fetch failed')
                });
                console.error(error);
            })
            .then(persons => {
                persons = persons
                    .sort((a, b) => {
                        let comparison = 0;
                        if (a['name'] > b['name']) comparison = 1;
                        else if (a['name'] < b['name']) comparison = -1;
                        return comparison;
                    });
                if (this._isMounted) {
                    this.setState({
                        persons: persons,
                        displayPersons: persons,
                        fetchFailed: false
                    });
                }
            })
            .catch(error => {
                this.setState({
                    persons: [],
                    displayPersons: [],
                    fetchFailed: true
                });
                console.error(error);
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({
                        isFetching: false
                    });
                }
            });
    }

    onFilterInputChange = (item) => {
        let filterValue = item.target.value;

        let searchableString = (p) =>
            `${p.name} ${p.surname} ` +
            `${p.date.year} ${p.date.month} ${p.date.day} `;


        let filteredPersons = this.state.persons
            .filter(person => searchableString(person)
                .toLocaleLowerCase()
                .includes(filterValue.toLocaleLowerCase()));
        this.setState({filterValue: filterValue, displayPersons: filteredPersons});
        console.log(`${filterValue} -> ${filteredPersons.length}`);
    };

    render() {
        const {persons, displayPersons, filterValue, isFetching, error} = this.state;
        const t = this.props.t;

        return (
            <div className="Persons">
                {
                    error === '' && !isFetching && persons.length === 0 && filterValue.trim() === '' &&
                    <Row><Col>{t('persons list:Add your first person')}</Col></Row>
                }
                <Row>
                    <Col>
                        <Link to={`/person/add`}>
                            <Button
                                size='md'
                                variant={'primary'}>
                                {t('persons list:Add a person')}
                            </Button>
                        </Link>
                    </Col>
                </Row>
                {error === '' && persons.length > 0 &&
                    <Row className="mb-3">
                        <Col>
                            <div>{t('persons list:You have X persons', {length: persons.length})}</div>
                        </Col>
                    </Row>
                }
                {error === '' && persons.length > 0 &&
                    <Row>
                        <Col xs={12} className={'filter-box'}>
                            <Form.Control
                                type="text"
                                placeholder={t('persons list:Filter by name')}
                                onChange={this.onFilterInputChange}
                            />
                        </Col>
                    </Row>
                }
                {
                    error !== '' &&
                    <div>{error}</div>
                }
                {
                    !isFetching && displayPersons.length === 0 && filterValue.trim() !== '' &&
                    <p>{t('persons list.Err no person found')}</p>
                }
                {
                    isFetching &&
                    <Loader/>
                }
                {
                    !isFetching &&
                    <PersonsList list={displayPersons}/>
                }
            </div>
        )
    }
}

export default withTranslation()(Persons);
