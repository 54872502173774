import React from 'react'

import './Calendar.css'
import Card from "react-bootstrap/Card";
import Loader from '../components/Loader'

const PsCard = props => {
    const summary = props.summary;
    return summary.map(number => (
        <Card key={number.title} border='info' className='text-center p-3'>
            {!props.fetching &&
            <Card.Header>
                {number.title}
            </Card.Header>
            }
            <Card.Body>
                {props.fetching &&
                <Card.Title>
                    <Loader/>
                </Card.Title>
                }
                <Card.Text>
                    {number.category !== undefined && number.category.summary !== undefined ?
                        number.category.summary :
                        ''}
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">{number.value}</small>
            </Card.Footer>
        </Card>
    ));
};

export default PsCard;
