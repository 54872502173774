import React from 'react'
import DayRow from './DayRow'

const MonthList = props => {

    return props.days &&
        props.days
            .map((day, index) => {
                    let dayKey = `${index + 1}/${props.monthName}/${props.fullYear}`;
                    return (
                        <DayRow
                            key={dayKey}
                            uniqueKey={dayKey}
                            index={index}
                            month={props.monthName}
                            year={props.fullYear}
                            day={day}
                        />
                    );
                }
            );
};
export default MonthList;
