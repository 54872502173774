import React, {Component} from 'react'
import Loader from '../../components/Loader'
import PersonHeader from '../../Person/Header'
import {Chart} from './Chart'
import {Luck, DayMaster, NormalLifeType, Relations, StarBinomials} from './Blocks'
import StemStrengths from './StemStrengths'
import './BaZiReport.css'
import Container from "react-bootstrap/Container";
import Service from '../../lib/Service'
import withRouter from "../../components/withRouter";

class BaZiReport extends Component {
    state = {
        report: null,
        personId: null,
        isFetching: false
    };

    componentDidMount() {
        console.log('Bazi report mounted');
        const id = this.props.params.id;
        this.setState({isFetching: true, personId: id});
        const params = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        fetch(`${Service.server}/api/bazi/${id}`, params)
            .then(response => {
                console.log('BaZi report request successful');
                return response.json();
            }, console.error)
            .then(report => {
                this.setState({report: report, isFetching: false});
            });
    }

    render() {
        const {report, isFetching} = this.state;
        
        const stemStrengthSize = {
            width: 600,
            height: 600
        };
        return (
            <Container className="bazi-report">
                <h2>BaZi analysis</h2>
                {!isFetching && report === null &&
                <p>Unable to find report with given id</p>
                }
                {isFetching &&
                <Loader/>
                }
                {!isFetching && report &&
                <div>
                    <PersonHeader personId={this.state.personId}/>
                </div>
                }
                {!isFetching && report &&
                <div>
                    <Chart
                        chart={report.chart.chart}
                        heavenlyStems={report.heavenlyStems}
                        earthlyBranches={report.earthlyBranches}
                    />
                </div>
                }
                {!isFetching && report &&
                <div>
                    <Luck
                        luck={report.chart.luck}
                        heavenlyStems={report.heavenlyStems}
                        earthlyBranches={this.state.report.earthlyBranches}
                    />
                </div>
                }
                {!isFetching && report &&
                <div>
                    <DayMaster
                        dm={report.dm}
                    />
                </div>
                }
                {!isFetching && report &&
                <div>
                    <NormalLifeType
                        normalLifeType={report.normalLifeType}
                    />
                </div>
                }
                {!isFetching && report && report.dm && report.godsScore &&
                <div>
                    <StemStrengths
                        stemScore={report.godsScore}
                        dayMasterId={report.dm.id}
                        size={stemStrengthSize}
                    />
                </div>
                }
                {!isFetching && report &&
                <div>
                    <StarBinomials
                        starBinomials={report.starBinomial}
                    />
                </div>
                }
                {!isFetching && report &&
                <div>
                    <Relations
                        relations={report.branchRelations}
                        chart={report.chart.chart}
                        heavenlyStems={report.heavenlyStems}
                        earthlyBranches={report.earthlyBranches}
                    />
                </div>
                }
            </Container>
        );
    }
}

export default withRouter(BaZiReport);
