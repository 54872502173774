import React from 'react';
import {useParams} from 'react-router-dom';

// Higher-Order Component to pass params to a class component
const withRouter = (Component) => {
    return (props) => {
        const params = useParams();
        return <Component {...props} params={params}/>;
    };
};

export default withRouter;
