import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './Chart.css'

const PillarName = props => (
    <div className='pillar-name'>
        {props.name}
    </div>
);

const Binomial = props => {
    const heavenlyStem = props.heavenlyStems[props.hs];
    const hsColorCode = heavenlyStem !== undefined ? heavenlyStem['color code'] : undefined;

    const earthlyBranch = props.earthlyBranches[props.eb];
    const ebColorCode = earthlyBranch !== undefined ? earthlyBranch['color code'] : undefined;

    return (
        <div className='binomial'>
            {props.hs &&
            <div className='heavenly-stem'
                 style={{backgroundColor: hsColorCode}}>
                {props.hs}
            </div>
            }
            {!props.hs && <div className='heavenly-stem'>-</div>}

            {props.eb &&
            <div className='earthly-branch'
                 style={{backgroundColor: ebColorCode}}>
                {props.eb}
            </div>
            }
            {!props.eb && <div className='earthly-branch'>-</div>}
        </div>
    );
};

const HiddenStems = props => (
    <div className='hidden-stems-group'>
        {props.hiddenStems && props.hiddenStems.h1 &&
        <div className='hidden-stem'
             style={{backgroundColor: props.heavenlyStems[props.hiddenStems.h1]['color code']}}>
            {props.hiddenStems && props.hiddenStems.h1}
        </div>
        }
        {props.hiddenStems && props.hiddenStems.h2 &&
        <div className='hidden-stem'
             style={{backgroundColor: props.heavenlyStems[props.hiddenStems.h2]['color code']}}>
            {props.hiddenStems.h2}
        </div>
        }
        {props.hiddenStems && props.hiddenStems.h3 &&
        <div className='hidden-stem'
             style={{backgroundColor: props.heavenlyStems[props.hiddenStems.h3]['color code']}}>
            {props.hiddenStems.h3}
        </div>
        }
    </div>
);

const Pillar = props => (
    <Col className='pillar'>
        <PillarName name={props.name}/>
        <Binomial hs={props.hs} eb={props.eb}
                  heavenlyStems={props.heavenlyStems}
                  earthlyBranches={props.earthlyBranches}
        />
        <HiddenStems hiddenStems={props.hiddenStems}
                     heavenlyStems={props.heavenlyStems}/>
    </Col>
);

const PillarSet = props => {
    const {pillarsSet, relation, chart, heavenlyStems, earthlyBranches} = props;
    return <Row id={relation.category}>
        {
            pillarsSet.map(pillar => {
                const name = pillar.pillar;
                return <Pillar
                    name={name}
                    hs={chart[name].hs}
                    eb={chart[name].eb}
                    hiddenStems={earthlyBranches[chart[name].eb]}
                    earthlyBranches={earthlyBranches}
                    heavenlyStems={heavenlyStems}
                    key={relation.category + name}/>
            })
        }
    </Row>
};

const Chart = props => (
    <Row>
        <Pillar
            name='Hour'
            hs={props.chart.hour.hs}
            eb={props.chart.hour.eb}
            hiddenStems={props.earthlyBranches[props.chart.hour.eb]}
            earthlyBranches={props.earthlyBranches}
            heavenlyStems={props.heavenlyStems}/>
        <Pillar
            name='Day'
            hs={props.chart.day.hs}
            eb={props.chart.day.eb}
            hiddenStems={props.earthlyBranches[props.chart.day.eb]}
            earthlyBranches={props.earthlyBranches}
            heavenlyStems={props.heavenlyStems}/>
        <Pillar
            name='Month'
            hs={props.chart.month.hs}
            eb={props.chart.month.eb}
            hiddenStems={props.earthlyBranches[props.chart.month.eb]}
            earthlyBranches={props.earthlyBranches}
            heavenlyStems={props.heavenlyStems}/>
        <Pillar
            name='Year'
            hs={props.chart.year.hs}
            eb={props.chart.year.eb}
            hiddenStems={props.earthlyBranches[props.chart.year.eb]}
            earthlyBranches={props.earthlyBranches}
            heavenlyStems={props.heavenlyStems}/>
    </Row>
);


export {Chart, PillarSet, Pillar, Binomial};
