import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from 'react-router-dom'
import Logo from '../assets/logo.png'
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import './Header.css'
import PropTypes from "prop-types";

const Header = (props) => {
    const isLoggedIn = props.authReducer?.user !== null;
    const t = props.t;

    return (
        <div className='header'>
            <Navbar bg="light" expand="lg">
                <Link to={'/'}>
                    <Navbar.Brand>
                        <img
                            alt=""
                            src={Logo}
                            width="90"
                            height="90"
                            className="d-inline-block align-center"
                        />
                        {' White Lotus'}
                    </Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title={t("layout:Analysis")} id="basic-nav-dropdown">
                            <Link className='dropdown-item'
                                  to={'/bazi-free-analysis'}>{t("layout:BaZi")}</Link>
                            <Link className='dropdown-item'
                                  to={'/ps-free-analysis'}>{t("layout:Numerology")}</Link>
                        </NavDropdown>
                        {isLoggedIn &&
                        <Link className='nav-link'
                              to={'/calendar'}>{t("layout:Calendar")}</Link>
                        }
                        {isLoggedIn &&
                        <Link className='nav-link'
                              to={'/persons'}>{t("layout:Persons")}</Link>
                        }
                        {isLoggedIn &&
                        <Link className='nav-link'
                              to={'/profile'}>{t("layout:Profile")}</Link>
                        }
                    </Nav>
                    {isLoggedIn &&
                    <Nav>
                        <Link className='nav-link'
                              to={'/login'}>{t("layout:Logout")}</Link>
                    </Nav>
                    }
                    {!isLoggedIn &&
                    <Nav>
                        <Link className='nav-link'
                              to={'/login'}>{t("layout:Login")}</Link>
                    </Nav>
                    }
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

Header.propTypes = {
    user: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        authReducer: state.authReducer
    }
};

//wrap App in connect and pass in mapStateToProps
export default connect(mapStateToProps)(withTranslation()(Header))
