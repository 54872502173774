import React, {Component} from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MonthList from "./List";
import BaZiCard from "./BaZiCard";
import PsCard from "./PsCard";
import Service from '../lib/Service'

class AnalysisCalendar extends Component {
    _isMounted = false;

    state = {
        currentDate: new Date(),

        cachedBaziMonths: {},
        baZiFetching: false,

        cachedPsMonths: {},
        psFetching: false
    };

    onChange = currentDate => {
        this.setState({currentDate}, () => {
            this.pullBaZiAnalysis(this.state.currentDate);
            this.pullPsMonthAnalysis(this.state.currentDate);
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({
            baZiFetching: false,
            psFetching: false
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.pullBaZiAnalysis(this.state.currentDate);
        this.pullPsMonthAnalysis(this.state.currentDate);
    }

    getMonthKey(currentDate) {
        return `${currentDate.getFullYear()}-${currentDate.getMonth()}`;
    }

    pullBaZiAnalysis = currentDate => {
        const monthKey = this.getMonthKey(currentDate);
        const cachedMonth = this.state.cachedBaziMonths[monthKey];
        if (cachedMonth !== undefined)
            return;

        console.info(`Pulling BaZi month analysis for ${this.state.currentDate}`);
        this.setState({baZiFetching: true});
        let reqBody = this.buildReqBodyFromCurrentDate(currentDate);
        reqBody.params.rules = ['star binomial'];
        const params = this.buildParams(reqBody);

        fetch(`${Service.server}/api/bazi/month`, params)
            .then(response => {
                return response.json();
            })
            .catch(error => {
                console.error(error);
                return {};
            })
            .then(report => {
                if (this._isMounted) {
                    if(report.errno !== undefined) {
                        console.error("Error for bazi month calendar", report);
                        return;
                    }
                    console.debug(`Creating cache with key: ${monthKey}`);
                    let cache = this.state.cachedBaziMonths;
                    cache[monthKey] = report;
                    this.setState({
                        baZiFetching: false,
                        cachedBaziMonths: cache
                    });
                }
            });
    };

    pullPsMonthAnalysis = currentDate => {
        const monthKey = this.getMonthKey(currentDate);
        const cachedMonth = this.state.cachedPsMonths[monthKey];
        if (cachedMonth !== undefined)
            return;

        console.info(`Pulling numerology analysis for ${this.state.currentDate}`);
        this.setState({psFetching: true});
        let reqBody = this.buildReqBodyFromCurrentDate(currentDate);
        reqBody.params.rules = [
            'internal vibration',
            'external vibration',
            'cosmic vibration',
            'number descriptions'
        ];
        const params = this.buildParams(reqBody);
        fetch(`${Service.server}/api/psquare/month`, params)
            .then(response => {
                return response.json();
            })
            .catch(error => {
                console.error(error);
                return {};
            })
            .then(report => {
                if (this._isMounted) {
                    if(report.errno !== undefined) {
                        console.error("Error for ps month calendar", report);
                        return;
                    }
                    console.debug(`Creating cache with key: ${monthKey}`);
                    let cache = this.state.cachedPsMonths;
                    cache[monthKey] = report;
                    this.setState({
                        psFetching: false,
                        cachedPsMonths: cache
                    });
                }
            });
    };

    buildParams(reqBody) {
        return {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(reqBody)
        };
    }

    buildReqBodyFromCurrentDate(currentDate) {
        return {
            params: {
                date: {
                    month: currentDate.getMonth() + 1,
                    year: currentDate.getFullYear()
                }
            }
        };
    }

    isUsable(analysis) {
        return analysis !== undefined && analysis.length > 0;
    }

    zipArrays(baZiArray, psArray) {
        let days = [];
        const len = baZiArray.length;
        const useBaZi = this.isUsable(baZiArray);
        const usePs = this.isUsable(psArray);

        for (let i = 0; i < len; i++) {
            days[i] = {};
            if (useBaZi) (days[i].bazi = baZiArray[i]);
            if (usePs) (days[i].ps = psArray[i]);
        }
        return days;
    }

    render() {
        const {
            currentDate,
            cachedBaziMonths,
            baZiFetching,
            cachedPsMonths,
            psFetching
        } = this.state;
        const monthKey = this.getMonthKey(currentDate);
        const baZiMonthAnalysis = cachedBaziMonths[monthKey] !== undefined ?
            cachedBaziMonths[monthKey] :
            [];
        const psMonthAnalysis = cachedPsMonths[monthKey] !== undefined ?
            cachedPsMonths[monthKey] :
            [];

        const currentDay = currentDate.getDate() - 1;
        const psDayAnalysis = psMonthAnalysis[currentDay] !== undefined ?
            psMonthAnalysis[currentDay] :
            {};
        const baziDayAnalysis = baZiMonthAnalysis[currentDay] !== undefined ?
            baZiMonthAnalysis[currentDay] :
            {};

        let dayBinomial = '';
        let binomialStars = [];
        if (baziDayAnalysis.chart !== undefined) {
            const binomial = baziDayAnalysis.chart.chart.day;
            dayBinomial = binomial.hs + ' ' + binomial.eb;
            binomialStars = baziDayAnalysis.starBinomial;
        }
        const destinyNumber = psDayAnalysis.destiny === undefined ? '' : psDayAnalysis.destiny.id;
        const internalVibration = psDayAnalysis['internal vibration'] === undefined ?
            '' :
            psDayAnalysis['internal vibration'].id;
        const externalVibration = psDayAnalysis['external vibration'] === undefined ?
            '' :
            psDayAnalysis['external vibration'].id;
        const cosmicVibration = psDayAnalysis['cosmic vibration'] === undefined ?
            '' :
            psDayAnalysis['cosmic vibration'].id;
        const numerologySummary = [
            {
                title: 'Destiny',
                value: destinyNumber,
                category: !psDayAnalysis.generalDigits ||
                    psDayAnalysis.generalDigits.filter(obj => obj.id === destinyNumber)[0]
            },
            {
                title: 'Internal vibration',
                value: internalVibration,
                category: !psDayAnalysis.generalDigits ||
                    psDayAnalysis.generalDigits.filter(obj => obj.id === internalVibration)[0]
            },
            {
                title: 'External vibration',
                value: externalVibration,
                category: !psDayAnalysis.generalDigits ||
                    psDayAnalysis.generalDigits.filter(obj => obj.id === externalVibration)[0]
            },
            {
                title: 'Cosmic vibration',
                value: cosmicVibration,
                category: !psDayAnalysis.generalDigits ||
                    psDayAnalysis.generalDigits.filter(obj => obj.id === cosmicVibration)[0]
            }
        ];
        const monthName = currentDate.toLocaleString('en-us', {month: 'long'});
        const fullYear = currentDate.getFullYear();

        let days = this.zipArrays(baZiMonthAnalysis, psMonthAnalysis);
        return (
            <div className="calendar">
                <div>
                    <h4>Calendar</h4>
                </div>
                <Container>
                    <Row className='centered' size='xxl'>
                        <Col xs={12} md={4}>
                            <Calendar
                                onChange={this.onChange}
                                value={this.state.currentDate}
                            />
                        </Col>
                        <Col xs={12} md={8}>
                            <Row>
                                <Col md={6}>
                                    <BaZiCard
                                        fetching={baZiFetching}
                                        dayBinomial={dayBinomial}
                                        binomialStars={binomialStars}
                                    />
                                </Col>
                                <Col md={6}>
                                    <PsCard
                                        fetching={psFetching}
                                        summary={numerologySummary}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {

                        this.isUsable(baZiMonthAnalysis) &&
                        <MonthList
                            monthName={monthName}
                            fullYear={fullYear}
                            days={days}
                        />
                    }
                </Container>
            </div>
        );
    }

}

export default AnalysisCalendar;
