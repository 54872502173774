import React, {Component} from 'react'
import Loader from '../components/Loader'
import PersonName from './Name'
import {DateDetails, TimeDetails} from "./DateDetails";

import './Header.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Service from '../lib/Service'

class PersonHeader extends Component {
    state = {
        person: null,
        isFetching: false
    };

    componentDidMount() {
        const id = this.props.personId;
        this.setState({isFetching: true});
        const params = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        fetch(`${Service.server}/api/persons/${id}`, params)
            .then(response => {
                console.log('Person details request successful');
                return response.json();
            }, console.error)
            .then(person => {
                this.setState({person: person, isFetching: false});
            });
    }

    render() {
        const {person, isFetching} = this.state;

        return (
            <Row className="person">
                {!isFetching && person &&
                <Col>

                    <PersonName
                        name={this.state.person.name}
                        surname={this.state.person.surname}
                    />
                    <DateDetails date={this.state.person.date}/>
                </Col>
                }
                {!isFetching && person &&
                <Col>
                    <TimeDetails person={this.state.person}/>
                </Col>
                }
                {!isFetching && person === null &&
                <Col>
                    <p>Unable to find person with given id</p>
                </Col>
                }
                {isFetching && person !== null &&
                <Col>
                    <Loader/>
                </Col>
                }
            </Row>
        );
    }
}

export default PersonHeader;
