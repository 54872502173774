import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import './PrivacyPolicy.css'; // Import the CSS file
import {Link} from 'react-router-dom'
import {withTranslation} from 'react-i18next';

const PrivacyPolicy = props => {
    const t = props.t;

    return (
        <Container>
            <Row className="privacy-policy-title">
                <h1>{t('privacy policy:title')}</h1>
            </Row>
            <Row>
                <div><p>{t('privacy policy:effective date')} 13 Jun 2024</p></div>
            </Row>
            <Row className="privacy-policy-heading">
                <h2>{t('privacy policy:intro title')}</h2>
            </Row>
            <Row>
                <div><p>{t('privacy policy:intro content')}</p></div>
            </Row>
            <Row className="privacy-policy-heading">
                <h2>{t('privacy policy:data collection title')}</h2>
            </Row>
            <Row>
                <div><p>{t('privacy policy:data collection content')}</p></div>
            </Row>
            <Row className="privacy-policy-heading">
                <h2>{t('privacy policy:use of information title')}</h2>
            </Row>
            <Row>
                <div><p>{t('privacy policy:use of information content')}</p></div>
            </Row>
            <Row className="privacy-policy-heading">
                <h2>{t('privacy policy:data sharing title')}</h2>
            </Row>
            <Row>
                <div><p>{t('privacy policy:data sharing content')}</p></div>
            </Row>
            <Row className="privacy-policy-heading">
                <h2>{t('privacy policy:data protection title')}</h2>
            </Row>
            <Row>
                <div><p>{t('privacy policy:data protection content')}</p></div>
            </Row>
            <Row className="privacy-policy-heading">
                <h2>{t('privacy policy:user rights title')}</h2>
            </Row>
            <Row>
                <div><p>{t('privacy policy:user rights content')}</p></div>
            </Row>
            <Row className="privacy-policy-heading">
                <h2>{t('privacy policy:changes to the policy title')}</h2>
            </Row>
            <Row>
                <div><p>{t('privacy policy:changes to the policy content')}</p></div>
            </Row>
            <Row className="privacy-policy-heading">
                <h2>{t('privacy policy:contact information title')}</h2>
            </Row>
            <Row>
                <div><p>{t('privacy policy:contact information content')}
                    <Link className='nav-link'
                          to={'/contact'}>{t("layout:Contact")}</Link>
                </p></div>
            </Row>
        </Container>
    );
}

export default withTranslation()(PrivacyPolicy);
