import * as types from './actionTypes';

export function setUser(user) {
    return dispatch => {
        console.info(`Set user ${user.email}`);
        return dispatch({
            type: types.LOGIN,
            userName: user.name,
            user: user.email
        });
    };
}

export function unsetUser() {
    return dispatch => {
        console.info('Unset user');
        return dispatch({
            type: types.LOGOUT,
            user: null,
            userName: null
        });
    };
}
