import React, {Component} from 'react';
import {GoogleLogin, googleLogout, GoogleOAuthProvider} from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';

import {Navigate} from 'react-router-dom'
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import * as loginActions from '../../../actions/loginActions';

const googleClientId = '551441825719-29ro8j3up3u0gllimuj901328baf4qrn.apps.googleusercontent.com';

class GoogleAuthButtons extends Component {
    state = {
        isLoggedIn: false,
        redirect: false
    };

    componentDidMount() {
        this.setState({
            isLoggedIn: localStorage.getItem('loggedUser') !== null
        })
    }

    handleGoogleLoginFailure = (error) => {
        console.log('Google Login Failed:', error);
        localStorage.setItem('login-source', 'google oauth failed');
    };


    handleGoogleLoginSuccess(currentObj) {
        return (response) => {
            const userObject = jwtDecode(response.credential);

            let email = userObject.email;
            let name = userObject.given_name;
            localStorage.setItem('loggedUser', email);
            localStorage.setItem('login-source', 'google oauth');
            this.props.setUser({email, name});
            currentObj.setState({isLoggedIn: true, redirect: true, name: name});
        };
    }

    handleLogout(currentObj) {
        return () => {
            googleLogout();
            localStorage.removeItem('loggedUser');
            localStorage.removeItem('login-source');
            localStorage.removeItem('user name');
            this.props.setUser({email: null, name: null, user: null});
            currentObj.setState({isLoggedIn: false, redirect: true, user: null});
        };
    }

    render() {
        const {isLoggedIn} = this.state;

        if (this.state.redirect) {
            return <Navigate to='/'/>
        }

        return <div>
            {
                !isLoggedIn &&
                <GoogleOAuthProvider clientId={googleClientId}>
                    <GoogleLogin
                        onSuccess={this.handleGoogleLoginSuccess(this)}
                        onFailure={this.handleGoogleLoginFailure}
                        clientId={googleClientId}
                        scope="email"
                        text="Login with Google"
                    />
                </GoogleOAuthProvider>
            }
            {
                isLoggedIn && <div>
                    <button onClick={this.handleLogout(this)}>Logout</button>
                </div>
            }
        </div>;
    }
}


GoogleAuthButtons.propTypes = {
    loginActions: PropTypes.object,
    user: PropTypes.string
};

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => dispatch(loginActions.setUser(user)),
        unsetUser: () => dispatch(loginActions.unsetUser())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleAuthButtons);