import React from 'react';
import {Route, Routes} from 'react-router-dom';
import BaZiReport from './Report/BaZi/BaZiReport'
import TermsAndConditions from './containers/TermsAndConditions'
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Contact from "./containers/Contact";
import PSquareReport from './Report/PSquare/PsFullReport'
import Calendar from './Calendar/Calendar'
import Login from './containers/Login'
import Persons from './containers/PersonsList'
import UserProfile from './containers/UserProfile'
import BaZiSingleReport from './Report/BaZi/BaZiSingleReport'
import PsSingleReport from './Report/PSquare/PsSingleReport'
import PersonDetails from './Person/Details'
import PersonAddForm from './Person/AddForm'
import Home from './containers/Home'

const AppRoutes = props => (
    <Routes>
        <Route exact path={'/login'} element={<Login/>}/>
        <Route exact path={'/calendar'} element={<Calendar/>}/>
        <Route exact path={'/persons'} element={<Persons/>}/>
        <Route exact path={'/profile'} element={<UserProfile/>}/>
        <Route exact path={'/bazi-free-analysis'} element={<BaZiSingleReport/>}/>
        <Route exact path={'/ps-free-analysis'} element={<PsSingleReport/>}/>
        <Route exact path={'/terms-and-conditions'} element={<TermsAndConditions/>}/>
        <Route exact path={'/privacy-policy'} element={<PrivacyPolicy/>}/>
        <Route exact path={'/contact'} element={<Contact/>}/>
        <Route path={'/person/add'} element={<PersonAddForm/>}/>
        <Route path={'/person/:id/bazi'} element={<BaZiReport/>}/>
        <Route path={'/person/:id/ps'} element={<PSquareReport/>}/>
        <Route path={'/person/:id/update'} element={<PersonAddForm/>}/>
        <Route path={'/person/:id'} element={<PersonDetails/>}/>
        <Route exact path={'/'} element={<Home/>}/>
    </Routes>
);

export default AppRoutes
