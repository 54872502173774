import React from 'react';
import Table from "react-bootstrap/Table";

const Square = props => {
    const {square} = props;
    return (
        <Table striped bordered hover>
            <tbody>
            <tr>
                <td>{square[1]}</td>
                <td>{square[4]}</td>
                <td>{square[7]}</td>
            </tr>
            <tr>
                <td>{square[2]}</td>
                <td>{square[5]}</td>
                <td>{square[8]}</td>
            </tr>
            <tr>
                <td>{square[3]}</td>
                <td>{square[6]}</td>
                <td>{square[9]}</td>
            </tr>
            </tbody>
        </Table>
    );
};

export default Square;
