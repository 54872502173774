import React from 'react';
import './Footer.css'
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import {withTranslation} from "react-i18next";

const Footer = props => {
    const t = props.t;

    return (
        <div className='footer'>
            <Navbar bg="light" expand="lg">
                    <Nav className='mr-auto copyright'>
                        &copy; Cristian Coțoi
                    </Nav>
                    <Nav className='links'>
                        <Link className='nav-link'
                              to={'/terms-and-conditions'}>{t('layout:Terms and conditions')}
                        </Link>
                        <Link className='nav-link'
                              to={'/privacy-policy'}>{t('privacy policy:title')}
                        </Link>
                        <Link className='nav-link'
                              to={'/contact'}>{t('layout:Contact')}
                        </Link>
                    </Nav>
            </Navbar>
        </div>
    );
};

export default withTranslation()(Footer);
