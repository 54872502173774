import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {isUndefined} from "lodash";
import {Pillar, PillarSet} from "./Chart";
import './Luck.css'

const DayMaster = props => {
    if (isUndefined(props.dm)) return <div/>;
    return (
        <Container id='day-master' className='col-sm-12'>
            <Row>
                <h4>Day master {props.dm.id}</h4>
            </Row>
            <Row>
                <div className='col-md-12 text-with-newlines'>{props.dm.description}</div>
            </Row>
            <Row>
                <Col sm={12} md={6} className='bg-success top-buffer'>{props.dm.positive}</Col>
                <Col sm={12} md={6} className='bg-danger top-buffer'>{props.dm.negative}</Col>
            </Row>
            <Row>
                <Col className="col-md-6 col-sm-12 text-primary top-buffer">
                    <strong>Path to happiness: </strong>{props.dm.path_to_happiness}
                </Col>
                <Col className="col-md-6 col-sm-12 text-primary top-buffer">
                    <strong>Happiness: </strong>{props.dm.happiness}
                </Col>
            </Row>
        </Container>
    );
};

const NormalLifeType = props => {
    if (isUndefined(props.normalLifeType)) return <div/>;
    const {name, description} = props.normalLifeType;
    if (isUndefined(name) || isUndefined(description)) return <div/>;
    return (
        <Container id='normal-life-type'>
            <h2>Normal life type: {name}</h2>
            <Row>
                {description}
            </Row>
        </Container>
    );
};

const StarBinomials = props => {
    const {starBinomials} = props;
    return (
        <Container id='star-binomials'>
            <Row>
                <h2>Day binomials</h2>
            </Row>
            {
                starBinomials !== undefined &&
                starBinomials.length > 0 &&
                starBinomials.map(star => {
                        return (
                            <Row key={'star binomial ' + star.category.title}>
                                <h3>{star.category.title} {star.day_hs} {star.day_eb}</h3>
                                <Row>
                                    {star.category.description}
                                </Row>
                            </Row>)
                    }
                )}
        </Container>
    );
};

const Relations = props => {
    const {relations, chart, heavenlyStems, earthlyBranches} = props;
    if (isUndefined(relations)) return <div/>;
    return (
        <Container id='chart relations'>
            <h2>Relations</h2>
            {relations
                .map(relationInfo => {
                        const {relation, matchingPillars} = relationInfo;
                        let branchesInRelation = relation.eb1;
                        if (relation.eb2) branchesInRelation += ` ${relation.eb2}`;
                        if (relation.eb3) branchesInRelation += ` ${relation.eb3}`;
                        return (
                            <Row key={'relations' + relation.id}>
                                <Row>
                                    <Col sm={12} md={8}>
                                        <Row>
                                            <Col>{relation.category}</Col>
                                            <Col>{branchesInRelation}</Col>
                                        </Row>
                                        {relation['category description'] &&
                                        <Row>{relation['category description']}</Row>
                                        }
                                        <Row>{relation.description}</Row>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        {matchingPillars.map(pillarsSet => {
                                            return <PillarSet
                                                pillarsSet={pillarsSet}
                                                relation={relation}
                                                chart={chart}
                                                heavenlyStems={heavenlyStems}
                                                earthlyBranches={earthlyBranches}
                                            key={relation.category}/>;
                                        })}
                                    </Col>
                                </Row>
                            </Row>
                        );
                    }
                )
            }
        </Container>
    );
};

const Luck = props => (
    <div className='container-fluid' id='luck-pillars'>
        <h2>Luck pillars</h2>
        <ul className='row flex-row flex-nowrap pillars'>
            {props.luck
                .map(pillar => (
                        <Col xs={6} sm={3} md={2} lg={1} key={'luck_' + pillar.hs + '_' + pillar.eb}>
                            <li>
                                <Pillar
                                    name={pillar.start + ' ' + pillar.end}
                                    hs={pillar.hs}
                                    eb={pillar.eb}
                                    hiddenStems={props.earthlyBranches[pillar.eb]}
                                    earthlyBranches={props.earthlyBranches}
                                    heavenlyStems={props.heavenlyStems}
                                />
                            </li>
                        </Col>
                    )
                )
            }
        </ul>
    </div>
);

export {Relations, DayMaster, NormalLifeType, StarBinomials, Luck};