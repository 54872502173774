import React from 'react'

import './Calendar.css'
import Card from "react-bootstrap/Card";
import Loader from '../components/Loader'

const BaZiCard = props => {

    return props.binomialStars && props.binomialStars.map(star => (
        <Card key={star.id} border={star.category.polarity} className='text-center p-3'>
            {!props.fetching &&
            <Card.Header>
                {star.category.title}
            </Card.Header>
            }
            <Card.Body>
                {props.fetching &&
                <Card.Title>
                    <Loader/>
                </Card.Title>
                }
                <Card.Text>{star.category.description}</Card.Text>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">{props.dayBinomial}</small>
            </Card.Footer>
        </Card>
    ));

};
export default BaZiCard;
