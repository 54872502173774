import React from 'react';
import Container from 'react-bootstrap/Container';
import './TermsAndConditions.css'
import Row from "react-bootstrap/Row";
import {withTranslation} from "react-i18next";

const TermsAndConditions = props => {
    const t = props.t;

    return (
        <Container>
            <Row>
                {t('terms and conditions:content')}
            </Row>
        </Container>
    );
}

export default withTranslation()(TermsAndConditions);
