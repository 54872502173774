import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Radiobox, Textbox} from "react-inputs-validation";
import Loader from '../../components/Loader'
import TimezoneSelect from "react-timezone-select"
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import '../index.css'
import Service from "../../lib/Service";

import Square from './Square'
import {Navigate} from 'react-router-dom'
import {Paragraph, Vibrations} from "./Blocks";
import i18next from "i18next";

const GENDER_INPUT_LIST = [
    // {id: "not applicable", name: "N/A"},
    {id: "F", name: "Female"},
    {id: "M", name: "Male"}
];

const DST_INPUT_LIST = [
    {id: "true", name: "Yes"},
    {id: "false", name: "No"}
];

class PsSingleReport extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        const today = new Date();
        const defaultPerson = {
            name: '',
            surname: '',
            date: {
                day: today.getDate(),
                month: today.getMonth() + 1,
                year: today.getFullYear()
                // , hour: 1, minutes: 20
            },
            birthTimezoneCityValue: 'Europe/Bucharest',
            birthTimezone: 2,
            birthCityLongitude: 27.35,
            isDstActiveAtBirth: true,
            gender: 'not applicable'
        };

        const givenPersonId = this.props.match?.params.id;

        this.state = {
            person: defaultPerson,
            givenPersonId: givenPersonId,
            timezone: "Europe/Bucharest",
            errorMessage: undefined,

            isFetching: false,
            fetchFailed: false,

            hasNameError: true,
            hasSurnameError: true,
            hasDayError: false,
            hasMonthError: false,
            hasYearError: false,
            hasHourError: false,
            hasMinuteError: false,
            hasTimezoneError: true,
            hasLongitudeError: true,
            hasGenderError: true,

            pSquare: {},
            isFetchingPs: false,
            redirectToDetailsPage: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchCharts();

        const givenPersonId = this.state.givenPersonId;
        if (givenPersonId !== undefined)
            Service
                .personDetails(givenPersonId)
                .then(person => {
                    this.setState({
                        person: person,
                        hasNameError: false,
                        hasSurnameError: false
                    })
                });
    }

    fetchCharts() {
        this.setState({
            isFetchingBaZi: true,
            isFetchingPs: true
        });
        const date = this.state.person.date;
        const lang = i18next.language || window.localStorage.i18nextLng || '';
        const extraRules = ['spirit level', 'operational digits descriptions',
            'destiny', 'internal vibration', 'external vibration', 'cosmic vibration'];
        Service
            .numerologyChartForDate(date, lang, extraRules)
            .then(pSquare => {
                this.setState({pSquare: pSquare, isFetchingPs: false});
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit(event) {
        event.preventDefault();

        const userId = localStorage.getItem('loggedUser');
        let person = this.state.person;
        person.analystId = userId;
        let url = `${Service.server}/api/persons`;
        let params = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(person)
        };

        // If provided an id in url, then it's the person update page
        if (this.state.givenPersonId !== undefined) {
            params.method = 'PUT';
            url += '/' + this.state.givenPersonId;
        }

        this.setState({
            isFetching: true,
            errorMessage: undefined
        });

        fetch(url, params)
            .then(response => {
                console.info('Person add request successful');
                return response.json();
            })
            .catch(error => {
                this.setState({
                    fetchFailed: true,
                    isFetching: false,
                    error: 'Person add failed'
                });
                console.error(error);
            })
            .then(() => {
                console.info('Person add successful');
                this.setState({redirectToDetailsPage: true})
            })
            .catch(error => {
                this.setState({
                    fetchFailed: true
                });
                console.error(error);
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({
                        isFetching: false
                    });
                }
            });

        event.preventDefault();
    }

    isPersonObjectInvalid() {
        let {
            person,
            hasNameError,
            hasSurnameError,
            hasDayError,
            hasMonthError,
            hasYearError,
            hasHourError,
            hasMinuteError
        } = this.state;

        const hourIsDefined = person.date.hour !== undefined;
        const minutesIsDefined = person.date.minutes !== undefined;

        return !person.name || (person.name && hasNameError) ||
            !person.surname || (person.surname && hasSurnameError) ||
            !person.date.day || (person.date.day && hasDayError) ||
            !person.date.month || (person.date.month && hasMonthError) ||
            !person.date.year || (person.date.year && hasYearError) ||
            (hourIsDefined && hasHourError) ||
            (minutesIsDefined && hasMinuteError);
    }

    handleTimezoneChange = (timezone) => {
        const person = {...this.state.person};
        person.birthTimezoneCityValue = timezone.value;
        person.birthTimezone = timezone.offset
        this.setState({person});
    };

    render() {
        const {
            person, isFetching, fetchFailed,
            pSquare, isFetchingPs
        } = this.state;

        if (this.state.redirectToDetailsPage)
            return <Navigate to={'/person/' + this.state.givenPersonId}/>;

        const isLoggedIn = localStorage.getItem('loggedUser') !== null;

        const isInvalid = this.isPersonObjectInvalid();
        const showDateDetails =
            person.date.hour !== undefined && person.date.hour !== '' &&
            person.date.minutes !== undefined && person.date.minutes !== '';
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    {isLoggedIn &&
                    <Row>
                        <Col xs={12} md={6}>
                            <Textbox
                                tabIndex={1}
                                id={'surname'}
                                name="Surname"
                                type="text"
                                value={person.surname}
                                validate={true}
                                placeholder="Surname"
                                validationCallback={res => {
                                    this.setState({
                                        hasSurnameError: res
                                    });
                                }}
                                onChange={(surname) => {
                                    let person = this.state.person;
                                    person.surname = surname;
                                    this.setState({person});
                                }}
                                onBlur={(e) => {
                                }}
                                validationOption={{
                                    name: 'Surname',
                                    check: true,
                                    min: 1,
                                    max: 100,
                                    reg: /[\w\s\d-]+/,
                                    regMsg: 'Surname should contain letters, digits, spaces and dashes',
                                    required: true
                                }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Textbox
                                tabIndex={2}
                                id={'name'}
                                name="Name"
                                type="text"
                                value={person.name}
                                placeholder="Family name"
                                validate={true}
                                validationCallback={res => {
                                    this.setState({
                                        hasNameError: res
                                    });
                                }}
                                onChange={(name) => {
                                    let person = this.state.person;
                                    person.name = name;
                                    this.setState({person});
                                }}
                                onBlur={(e) => {
                                }}
                                validationOption={{
                                    name: 'Name',
                                    check: true,
                                    min: 1,
                                    max: 100,
                                    reg: /[\w\s\d-]+/,
                                    regMsg: 'Name should contain letters, digits, spaces and dashes',
                                    required: true
                                }}
                            />
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col xs={4}>
                            <label>Day:
                                <Textbox
                                    tabIndex={3}
                                    id={'day'}
                                    type="number"
                                    value={person.date.day}
                                    placeholder="Day"
                                    validate={true}
                                    validationCallback={res => {
                                        this.setState({
                                            hasDayError: res
                                        });
                                    }}
                                    onChange={(value) => {
                                        let person = this.state.person;
                                        person.date.day = parseFloat(value);
                                        this.setState({person});
                                        this.fetchCharts();
                                    }}
                                    onBlur={(e) => {
                                    }}
                                    validationOption={{
                                        name: 'day',
                                        check: true,
                                        min: 1,
                                        max: 31,
                                        required: true
                                    }}
                                />
                            </label>
                        </Col>
                        <Col xs={4}>
                            <label>Month:
                                <Textbox
                                    tabIndex={4}
                                    id={'month'}
                                    type="number"
                                    value={person.date.month}
                                    placeholder="Month"
                                    validate={true}
                                    validationCallback={res => {
                                        this.setState({
                                            hasMonthError: res
                                        });
                                    }}
                                    onChange={(value) => {
                                        let person = this.state.person;
                                        person.date.month = parseFloat(value);
                                        this.setState({person});
                                        this.fetchCharts();
                                    }}
                                    onBlur={(e) => {
                                    }}
                                    validationOption={{
                                        name: 'month',
                                        check: true,
                                        min: 1,
                                        max: 31,
                                        required: true
                                    }}
                                />
                            </label>
                        </Col>
                        <Col xs={4}>
                            <label>Year:
                                <Textbox
                                    tabIndex={5}
                                    id={'year'}
                                    type="number"
                                    value={person.date.year}
                                    placeholder="Year"
                                    validate={true}
                                    validationCallback={res => {
                                        this.setState({
                                            hasYearError: res
                                        });
                                    }}
                                    onChange={(value) => {
                                        let person = this.state.person;
                                        person.date.year = parseFloat(value);
                                        this.setState({person});
                                        this.fetchCharts();
                                    }}
                                    onBlur={(e) => {
                                    }}
                                    validationOption={{
                                        name: 'year',
                                        check: true,
                                        required: true
                                    }}
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <label>Hour:
                                <Textbox
                                    tabIndex={6}
                                    id={'hour'}
                                    type="number"
                                    value={person.date.hour}
                                    placeholder="Hour"
                                    validate={true}
                                    validationCallback={res => {
                                        this.setState({
                                            hasHourError: res
                                        });
                                    }}
                                    onChange={(value) => {
                                        let person = this.state.person;
                                        person.date.hour = parseFloat(value);
                                        this.setState({person});
                                        this.fetchCharts();
                                    }}
                                    onBlur={(e) => {
                                    }}
                                    validationOption={{
                                        name: 'hour',
                                        check: true,
                                        min: 0,
                                        max: 24,
                                        required: false,
                                        customFunc: hour => {
                                            if (hour === undefined || (hour >= 0 && hour <= 24))
                                                return true;
                                            return "Please enter a valid hour"
                                        }
                                    }}
                                />
                            </label>
                        </Col>
                        <Col xs={4}>
                            <label>Minutes:
                                <Textbox
                                    tabIndex={7}
                                    id={'minutes'}
                                    type="number"
                                    value={person.date.minutes}
                                    placeholder="Minutes"
                                    validate={true}
                                    validationCallback={res => {
                                        this.setState({
                                            hasMinutesError: res
                                        });
                                        this.fetchCharts();
                                    }}
                                    onChange={(value) => {
                                        let person = this.state.person;
                                        person.date.minutes = parseFloat(value);
                                        this.setState({person});
                                    }}
                                    onBlur={(e) => {
                                    }}
                                    validationOption={{
                                        name: 'minutes',
                                        check: true,
                                        min: 0,
                                        max: 60,
                                        required: false,
                                        customFunc: minutes => {
                                            if (minutes === undefined || (minutes >= 0 && minutes <= 60))
                                                return true;
                                            return "Please enter a valid number of minutes"
                                        }
                                    }}
                                />
                            </label>
                        </Col>
                    </Row>
                    {showDateDetails &&
                        <Row>
                            <Col xs={12}>
                                <label>
                                    Timezone:
                                    <div className="select-wrapper">
                                        <TimezoneSelect
                                            value={this.state.person.birthTimezoneCityValue}
                                            onChange={this.handleTimezoneChange}/>
                                    </div>
                                </label>
                            </Col>
                        </Row>
                    }
                    {person.date.hour && person.date.minutes &&
                        <Row>
                            <Col xs={4}>
                                <label>Longitude:
                                    <Textbox
                                        tabIndex={9}
                                        id={'birthCityLongitude'}
                                        type="number"
                                        value={person.birthCityLongitude}
                                    placeholder="Longitude"
                                    validate={true}
                                    validationCallback={res => {
                                        this.setState({
                                            hasLongitudeError: res
                                        });
                                    }}
                                    onChange={(value) => {
                                        let person = this.state.person;
                                        person.birthCityLongitude = parseFloat(value);
                                        this.setState({person});
                                    }}
                                    onBlur={(e) => {
                                    }}
                                    validationOption={{
                                        name: 'birthCityLongitude',
                                        check: true,
                                        min: -360,
                                        max: 360,
                                        required: false
                                    }}
                                />
                            </label>
                        </Col>
                    </Row>
                    }
                    {person.date.hour && person.date.minutes &&
                    <Row>
                        <Col xs={12}>
                            <label>
                                Daylight saving time enabled at birth:
                                <Radiobox
                                    tabIndex={10}
                                    id={'isDstActiveAtBirth'}
                                    name="isDstActiveAtBirth"
                                    disabled={false}
                                    value={this.state.person.isDstActiveAtBirth}
                                    validate={true}
                                    validationCallback={res =>
                                        this.setState({
                                            hasJobError: res,
                                            enableValidation: false
                                        })
                                    }
                                    optionList={DST_INPUT_LIST}
                                    classNameInput=""
                                    classNameWrapper=""
                                    classNameContainer=""
                                    classNameOptionListItem=""
                                    customStyleInput={{}}
                                    customStyleWrapper={{}}
                                    customStyleContainer={{
                                        display: "flex",
                                        justifyContent: "flex-start"
                                    }}
                                    customStyleOptionListItem={{marginRight: "20px"}}
                                    onChange={(gender) => {
                                        let person = this.state.person;
                                        person.gender = gender;
                                        this.setState({person});
                                    }}
                                    onBlur={e => {
                                    }}
                                />
                            </label>
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col xs={12}>
                            <label>
                                Gender:
                                <Radiobox
                                    tabIndex={11}
                                    id="gender"
                                    name="gender"
                                    disabled={false}
                                    value={this.state.person.gender}
                                    validate={true}
                                    validationCallback={gender =>
                                        this.setState({
                                            hasGenderError: gender,
                                            enableValidation: false
                                        })
                                    }
                                    optionList={GENDER_INPUT_LIST}
                                    classNameInput=""
                                    classNameWrapper=""
                                    classNameContainer=""
                                    classNameOptionListItem=""
                                    customStyleInput={{}}
                                    customStyleWrapper={{}}
                                    customStyleContainer={{
                                        display: "flex",
                                        justifyContent: "flex-start"
                                    }}
                                    customStyleOptionListItem={{marginRight: "20px"}}
                                    onChange={(gender) => {
                                        let person = this.state.person;
                                        person.gender = gender;
                                        this.setState({person});
                                    }}
                                    onBlur={e => {
                                    }}
                                />
                            </label>
                        </Col>
                    </Row>
                    {isLoggedIn &&
                    <Row>
                        <Col xs={12}>
                            <label>
                                <Textbox
                                    tabIndex={12}
                                    id={'notes'}
                                    name="Notes"
                                    type="text"
                                    value={person.notes}
                                    placeholder="Notes"
                                    validationCallback={res => {
                                        this.setState({
                                            hasNotesError: res
                                        });
                                    }}
                                    onChange={(notes) => {
                                        let person = this.state.person;
                                        person.notes = notes;
                                        this.setState({person});
                                    }}
                                    onBlur={(e) => {
                                    }}
                                    validationOption={{
                                        name: 'Notes',
                                        check: true,
                                        min: 1,
                                        max: 200,
                                        reg: /[\w\s\d-.,&@#]*/,
                                        // regMsg: 'Surname should contain letters, digits, spaces and dashes',
                                        required: false
                                    }}
                                />
                            </label>
                        </Col>
                    </Row>
                    }
                    <Row>
                        {!isInvalid && isLoggedIn &&
                        <div
                            onClick={this.handleSubmit}>
                            <Button
                                size='lg'
                                disabled={isInvalid}
                                variant={isInvalid ? "danger" : "primary"}
                            >Save
                            </Button>
                            {isFetching &&
                            <Loader/>
                            }
                        </div>
                        }
                        {!isLoggedIn &&
                        <span>Please login before adding persons</span>
                        }
                        {isInvalid &&
                        <span>Please add a valid name and date</span>
                        }
                        {fetchFailed &&
                        <span>Failed to add person</span>
                        }
                        <input type="submit" style={{display: "none"}}/>
                    </Row>
                </form>
                <Row>
                    <Col xs={12} sm={6}>
                        {!isFetchingPs && pSquare.square &&
                        <Square
                            square={pSquare.square}
                        />
                        }
                    </Col>
                </Row>
                {!isFetching && pSquare && pSquare.destiny &&
                <Vibrations
                    spiritualLevel={pSquare.spiritLevel}
                    destiny={pSquare.destiny}
                    internalVibration={pSquare['internal vibration']}
                    externalVibration={pSquare['external vibration']}
                    cosmicVibration={pSquare['cosmic vibration']}
                />
                }
                {!isFetching && pSquare && pSquare.op &&
                <div>
                    <h2>Operational numbers</h2>
                    {
                        pSquare.op.map(opNumber => {
                                const description = opNumber.details !== undefined ?
                                    opNumber.details.description :
                                    '';
                                return (
                                    <Paragraph
                                        key={`Operational number ${opNumber.position}: `}
                                        mainTitle={`Operational number ${opNumber.position}: `}
                                        additionalTitle={opNumber.number}
                                        description={description}
                                    />
                                );
                            }
                        )
                    }
                </div>
                }
            </div>
        );
    }
}

export default PsSingleReport;
