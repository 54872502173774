import React from 'react'
import {Row, Col, Badge} from "react-bootstrap";


const CalendarDayRow = props => {
    const ps = props.day.ps;
    const bazi = props.day.bazi;

    return (bazi || ps) &&
        <Row className='calendar-day-row'>
            <Col xs={12} md={2}>{props.index + 1}/{props.month}/{props.year}</Col>
            <Col xs={12} md={5}>
                {bazi.starBinomial.map((star, starIndex) => {
                    return (
                        <Badge
                            key={props.uniqueKey + star.category.title + starIndex}
                            bg={star.category.polarity}>
                            {star.category.title}
                        </Badge>
                    );
                })
                }
            </Col>
            <Col xs={12} md={5}>
                {ps !== undefined && ps.destiny !== undefined &&
                    <Badge
                        key={props.uniqueKey + ' destiny'}
                        bg='warning'>
                        Destiny: {ps.destiny.id}
                    </Badge>
                }
                {ps !== undefined && ps['internal vibration'] !== undefined &&
                    <Badge
                        key={props.uniqueKey + ' internal vibration'}
                        bg='light'>
                        Internal: {ps['internal vibration'].id}
                    </Badge>
                }
                {ps !== undefined && ps['external vibration'] !== undefined &&
                    <Badge
                        key={props.uniqueKey + ' external vibration'}
                        bg='light'>
                        External: {ps['external vibration'].id}
                    </Badge>
                }
                {ps !== undefined && ps['cosmic vibration'] !== undefined &&
                    <Badge
                        key={props.uniqueKey + ' cosmic vibration'}
                        bg='light'>
                        Cosmic: {ps['cosmic vibration'].id}
                    </Badge>
                }

            </Col>
        </Row>;
};
export default CalendarDayRow;
