import React, {Component} from 'react'
import Loader from '../../components/Loader'
import PersonHeader from '../../Person/Header'
import Square from './Square'
import {Paragraph, Vibrations} from './Blocks'
import './PsFullReport.css'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {isUndefined} from "lodash";
import Service from '../../lib/Service'
import withRouter from "../../components/withRouter";

class PsReport extends Component {
    state = {
        report: null,
        personId: null,
        isFetching: false
    };

    componentDidMount() {
        console.log('Numerology report mounted');
        const id = this.props.params.id;
        this.setState({isFetching: true, personId: id});
        const params = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        fetch(`${Service.server}/api/psquare/${id}`, params)
            .then(response => {
                console.log('PS report request successful');
                return response.json();
            }, console.error)
            .then(report => {
                this.setState({report: report, isFetching: false});
            });
    }

    render() {
        const {report, isFetching} = this.state;
        return (
            <Container className="ps-report">
                <h2>Numerology analysis</h2>
                {!isFetching && report === null &&
                <p>Unable to find report with given id</p>
                }
                {isFetching &&
                <Loader/>
                }
                {!isFetching && report &&
                <div>
                    <PersonHeader personId={this.state.personId}/>
                </div>
                }
                {!isFetching && report &&
                <Square
                    square={report.square}
                />
                }
                {!isFetching && report &&
                <Vibrations
                    spiritualLevel={report.spiritLevel}
                    destiny={report.destiny}
                    internalVibration={report['internal vibration']}
                    externalVibration={report['external vibration']}
                    cosmicVibration={report['cosmic vibration']}
                />
                }
                {!isFetching && report &&
                <div>
                    <h2>Operational numbers</h2>
                    {
                        report.op.map(opNumber => {
                                const {position, number, details} = opNumber;
                                let description = '';
                                if (!isUndefined(details)) {
                                    description = details.description;
                                }
                                return (
                                    <Paragraph
                                        key={`Operational number ${position}: `}
                                        mainTitle={`Operational number ${position}: `}
                                        additionalTitle={number}
                                        description={description}
                                    />
                                );
                            }
                        )
                    }
                </div>
                }
                {!isFetching && report &&
                <DigitsSignificance
                    squareMeanings={report.sqMeaning}
                    squareDigits={report.square}
                />
                }
                {!isFetching && report && report['sq combos'] &&
                <Combinations
                    squareCombos={report['sq combos']}
                    squareDigits={report.square}
                />
                }
                {!isFetching && report && report['sq combos'] &&
                <SquareLines
                    squareLines={report.lines}
                    linesWeight={report.linesWeight}
                />
                }
            </Container>
        );
    }
}

const DigitsSignificance = props => {
    const {squareMeanings, squareDigits} = props;
    if (isUndefined(squareMeanings)) return <div/>;

    return (<div>
        <h2>Digits significance</h2>
        {
            squareMeanings
                .filter(digitMeaning => digitMeaning !== null)
                .map(digitMeaning => {
                        const digitInSquare = squareDigits[digitMeaning.number];
                        const title = digitInSquare === '' ?
                            `Absence of digit ${digitMeaning.number}` :
                            `Digit ${digitInSquare}`;
                        return (
                            <Paragraph
                                key={title}
                                mainTitle={title}
                                description={digitMeaning.description}
                            />
                        );
                    }
                )
        }
    </div>);
};

const Combinations = props => {
    const {squareCombos, squareDigits} = props;
    return (
        <div>
            <h2>Combinations</h2>
            {
                squareCombos
                    .map(combo => {
                            const digits = Object.keys(combo)
                                .filter(key => key.includes('max'))
                                .map(key => parseInt(key.replace('max', '')));
                            const titleBlock = digits
                                .map(digit => squareDigits[digit])
                                .join(' - ');
                            const title = titleBlock === '' ?
                                'Absence of' :
                                'Combo';
                            const additionalTitle = titleBlock === '' ?
                                digits[0] :
                                titleBlock;
                            return (
                                <Paragraph
                                    key={combo.id}
                                    mainTitle={title}
                                    additionalTitle={additionalTitle}
                                    description={combo.description}
                                />
                            );
                        }
                    )
            }
        </div>
    );
};

const SquareLines = props => {
    const {squareLines, linesWeight} = props;
    const ordLines = [
        '123', '456', '789',
        '147', '258', '369',
        '159', '357'
    ];
    return (<div>
        <h2>Lines in the square</h2>
        {
            ordLines
                .map(lineName => {
                        const line = squareLines[lineName];
                        const lineNumber = line.id;
                        const linesWeightElement = linesWeight.filter(el => parseInt(el.number) === lineNumber)[0];
                        return (
                            <div key={lineNumber}>
                                <Row>
                                    <Col>
                                        <h3>{line.type} {lineNumber}</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h5>{line.name}</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        {line.wellbeing &&
                                        <div><strong>Aspect:</strong> {line.wellbeing}</div>}
                                    </Col>
                                    {linesWeightElement &&
                                    <Col sm={12} md={6}>
                                        <strong>Weight:</strong> {linesWeightElement.weight.count}
                                    </Col>}
                                    {line.level && <Col sm={12} md={6}>
                                        <strong>Level:</strong> {line.level}
                                    </Col>}
                                    {line.verb && <Col sm={12} md={6}>
                                        <strong>Verb:</strong> {line.verb}
                                    </Col>}
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        {line.description}
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Row> <Col>
                                            {linesWeightElement.description}
                                        </Col> </Row>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }
                )
        }
    </div>);
};

export default withRouter(PsReport);
