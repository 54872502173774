import React from 'react';
import * as d3 from "d3"
import './StemStrengths.css'
import {sumBy, values} from 'lodash'

const StemStrengthChart = props => {
    const {stemScore, dayMasterId, size} = props;
    const orderedStems = [
        '甲 wood+', '乙 wood-',
        '丙 fire+', '丁 fire-',
        '戊 earth+', '己 earth-',
        '庚 metal+', '辛 metal-',
        '壬 water+', '癸 water-'
    ];

    const width = size.width;
    const height = size.height;
    const innerData = [72, 72, 72, 72, 72];
    const outerData = [36, 36, 36, 36, 36, 36, 36, 36, 36, 36];

    const outerColorsRange = [
        '#669056', '#137912',
        '#E4503C', '#C12B0C',
        '#b78a60', '#906811',
        '#C4D5D4', '#717C7B',
        '#6D9FDA', '#4967DA'
    ];

    const dayMasterIndex = orderedStems.indexOf(dayMasterId);
    const dayMaster = {
        color: outerColorsRange[dayMasterIndex],
        id: dayMasterId
    };

    let innerColorsRange = [];
    for (let i = 0; i < outerColorsRange.length; i += 2) {
        innerColorsRange[i / 2] = d3.interpolateRgb(outerColorsRange[i], outerColorsRange[i + 1])(0.5);
    }

    const radius = Math.min(width, height) / 6;
    const innerRadius = radius / 2;

    let innerPie = d3.pie()(innerData);
    innerPie.colors = innerColorsRange;
    let outerPie = d3.pie()(outerData);
    outerPie.colors = outerColorsRange;
    outerPie.stemScores = values(stemScore);

    return (
        <svg height={height} width={width} className='stems-strength-pie-chart'>
            <g transform={`translate(${width / 2},${height / 2})`}
               className='arc inner'>
                <DayMasterCircle
                    dayMaster={dayMaster}
                    radius={innerRadius}/>
            </g>
            <g transform={`translate(${width / 2},${height / 2}) rotate(-36)`}
               className='arc inner'>
                <InnerRing
                    pie={innerPie}
                    innerRadius={innerRadius}
                    outerRadius={radius}
                    uniqueKey='arc inner'
                />
            </g>
            <g transform={`translate(${width / 2},${height / 2}) rotate(-36)`}
               className='arc outer'>
                <OuterRing
                    pie={outerPie}
                    innerRadius={radius}
                    uniqueKey='arc outer'
                    orderedStems={orderedStems}
                />
            </g>
        </svg>
    );
};

const DayMasterCircle = props => {
    let {dayMaster, radius} = props;

    return <g>
        <circle r={radius} fill={dayMaster.color}/>
        <text className='day-master'>{dayMaster.id}</text>
    </g>;
};

const InnerRing = props => {
    let {pie, innerRadius, outerRadius, uniqueKey} = props;

    let arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

    return pie.map((slice, index) => {
        return <g key={uniqueKey + index}>
            <StemStrengthSlice
                arcSlice={arc(slice)}
                color={pie.colors[index]}
                position={index}/>
        </g>;
    });
};

const OuterRing = props => {
    let {pie, innerRadius, uniqueKey, orderedStems} = props;
    const {stemScores} = pie;
    let arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(d => {
            const stemScoresElement = stemScores[d.index];
            return innerRadius * 1.03 + stemScoresElement.total / 1.5;
        });

    const totalStemValues = sumBy(stemScores, stem => stem.total);
    return orderedStems.map((stemName, index) => {
        const slice = pie[index];
        return <g key={uniqueKey + index}>
            <StemStrengthSlice
                arcSlice={arc(slice)}
                color={pie.colors[index]}
                stemStrength={stemScores[index].total}
                position={index}
                stem={stemName}
                totalStemValues={totalStemValues}/>
        </g>;
    });
};

const StemStrengthSlice = props => {
    const {arcSlice, color, stemStrength, position, stem, totalStemValues} = props;
    return <g className='stem-strength-slice'>
        <Slice
            arcSlice={arcSlice}
            color={color}
        />
        <SliceDescription
            arcSlice={arcSlice}
            color={color}
            position={position}
            stem={stem}
            stemStrength={stemStrength}
            totalStemValues={totalStemValues}/>
    </g>;
};

const Slice = props => {
    const {arcSlice, color} = props;

    return <path
        d={arcSlice}
        fill={color}
    />;
};

const SliceDescription = props => {
    const {arcSlice, color, position, stem, stemStrength, totalStemValues} = props;
    const squareWidth = 50;
    const squareHeight = 30;
    const stemScorePercent = stemStrength / totalStemValues * 100;
    const percent = parseFloat(stemScorePercent).toFixed(2);
    return <g transform={`rotate(${36 * (position + 1)}) translate(${-40},${-140})`}>
        <rect
            transform={`rotate(${-36 * position})`}
            x={-squareWidth / 2} y={-2} width={squareWidth} height={squareHeight}
            d={arcSlice}
            fill={color}
            className='text-background'
        />
        <text x={0} y={squareHeight / 3}
              transform={`rotate(${-36 * position})`}
              className='stem-name'
        >{stem}
        </text>
        {!isNaN(stemScorePercent) &&
        <text x={0} y={squareHeight / 4 * 3}
              transform={`rotate(${-36 * position})`}
              className='stem-value'
        >{percent}%
        </text>
        }
    </g>;
};

export default StemStrengthChart;
