import React, {Component} from 'react';
import './Main.css';
import Footer from './Footer';
import AppRoutes from '../Routes'
import Header from './Header'
import 'whatwg-fetch'


export class Main extends Component {
    render() {
        return (
            <div>
                <Header/>
                <main>
                    <AppRoutes/>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default Main;
