import React, {useState} from 'react';
import './List.css'
import {Button, ButtonGroup, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import SortComponent from "../components/SortComponent";
import {useTranslation} from 'react-i18next';

const Person = function ({details}) {
    const {t} = useTranslation();
    const monthNames = t('global', {returnObjects: true})['months'];

    const formatDate = ({month, day}) => {
        const monthName = monthNames[month - 1];
        return `${day} ${monthName}`;
    };

    return <li>
        <Row>
            <Col>
                {details.name} {details.surname}
            </Col>
            <Col>
                {formatDate(details.date)}
            </Col>
            <Col className={'d-flex'}>
                <ButtonGroup size="sm" className="mr-2 ml-auto">
                    <Link to={`/person/${details.id}/bazi`}>
                        <Button size="sm" variant="success">
                            BaZi
                        </Button>
                    </Link>
                    <Link to={`/person/${details.id}/ps`}>
                        <Button size="sm" variant="warning">
                            PMatrix
                        </Button>
                    </Link>
                </ButtonGroup>
                <ButtonGroup size="sm" className="ml-auto">
                    <Link to={`/person/${details.id}`}>
                        <Button size="sm" variant="info">
                            Details
                        </Button>
                    </Link>
                </ButtonGroup>
            </Col>
        </Row>
    </li>;
};

const ExtraLine = ({extraPersonsSize}) => (
    <li>
        ... {extraPersonsSize} more items
    </li>
);


const PersonsList = (props) => {
    const [sortMethod, setSortMethod] = useState('upcoming birthday');

    const handleSortChange = (method) => {
        setSortMethod(method);
    };

    const getNextBirthday = (date) => {
        const today = new Date();
        const nextBirthday = new Date(today.getFullYear(), date.month - 1, date.day);

        // This +1 makes the birthdays that happen today to appear at top of the list
        nextBirthday.setDate(nextBirthday.getDate() + 1);

        if (nextBirthday <= today) {
            nextBirthday.setFullYear(today.getFullYear() + 1);
        }

        return nextBirthday;
    };

    const sortPersons = (persons, method) => {
        return persons.slice().sort((a, b) => {
            if (method === 'name') {
                return String(a.name).localeCompare(String(b.name));
            } else if (method === 'upcoming birthday') {
                return getNextBirthday(a.date) - getNextBirthday(b.date);
            } else if (method === 'past birthday') {
                return getNextBirthday(b.date) - getNextBirthday(a.date);
            }
            return 0;
        });
    };

    const sortedList = sortPersons(props.list, sortMethod);
    let maxPersonsCount = 30;
    return (
        <Col xs={12} id="app-persons-list" className="App-Persons-List">
            <SortComponent onSortChange={handleSortChange}/>
            <ul className="persons-list">
                {sortedList.slice(0, maxPersonsCount).map((person) => (
                    <Person details={person} key={person.id}/>
                ))}
                {props.list.length > maxPersonsCount && <ExtraLine extraPersonsSize={props.list.length - maxPersonsCount}/>}
            </ul>
        </Col>
    );
};

export {PersonsList, Person, ExtraLine};
