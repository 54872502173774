import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {isUndefined} from "lodash";

const Paragraph = props => {
    const {mainTitle, additionalTitle, description, additionalDescription} = props;
    return (
        <div>
            <Row>
                <Col>
                    <h4>{mainTitle} {additionalTitle}</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    {description}
                </Col>
            </Row>
            {additionalDescription &&
            <Row>
                <Col>
                    {additionalDescription}
                </Col>
            </Row>
            }
        </div>
    );
};

const Vibrations = props => {
    const {spiritualLevel, destiny, internalVibration, externalVibration, cosmicVibration} = props;
    return <div>
        {isUndefined(props.hideTitle) &&
        <h2>Vibrations</h2>
        }
        {!isUndefined(spiritualLevel) &&
        <Paragraph
            mainTitle={'Spiritual level'}
            additionalTitle={spiritualLevel.id}
            description={spiritualLevel.description}
        />
        }
        {!isUndefined(destiny) &&
        <Paragraph
            mainTitle={'Destiny'}
            additionalTitle={destiny.id}
            description={destiny.description}
        />
        }
        {!isUndefined(internalVibration) &&
        <Paragraph
            mainTitle={'Internal vibration'}
            additionalTitle={internalVibration.id}
            description={internalVibration.description}
            additionalDescription={internalVibration.qualities}
        />
        }
        {!isUndefined(externalVibration) &&
        <Paragraph
            mainTitle={'External vibration'}
            additionalTitle={externalVibration.id}
            description={externalVibration.description}
        />
        }
        {!isUndefined(cosmicVibration) &&
        <Paragraph
            mainTitle={'Cosmic vibration'}
            additionalTitle={cosmicVibration.id}
            description={cosmicVibration.description}
        />
        }
    </div>
};

export {Paragraph, Vibrations};