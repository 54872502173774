import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SortComponent = ({ onSortChange }) => {
    const { t } = useTranslation();

    return (
        <Row>
            <Col xs={12}>
                <Form.Group controlId="sortSelect">
                    <Form.Label>{t('persons list:Sort by')}:</Form.Label>
                    <Form.Control as="select" onChange={(e) => onSortChange(e.target.value)}>
                        <option value="upcoming birthday">{t('persons list:Upcoming Birthday')}</option>
                        <option value="past birthday">{t('persons list:Past Birthday')}</option>
                        <option value="name">{t('persons list:Sort by name')}</option>
                    </Form.Control>
                </Form.Group>
            </Col>
        </Row>
    );
};

export default SortComponent;
