import React from 'react';

const DateDetails = props => (
    <span className="details">
        <span className="date">
            {props.date.day}/{props.date.month}/{props.date.year}
        </span>
    </span>
);

const TimeDetails = props => (
    <div className="details">
        {
            (props.person.date.hour || props.person.date.minutes) &&
            <div className="time">
                {props.person.date.hour}:{props.person.date.minutes}
            </div>
        }
        {
            (props.person.date.hour || props.person.date.minutes) &&
            <div className="time-adjustments">
                <div> Timezone: GMT+{props.person.birthTimezone}</div>
                <div>Birth city longitude: {props.person.birthCityLongitude}</div>
                <div>Daylight saving time at
                    birth: {props.person.isDstActiveAtBirth ? 'Yes' : 'No'}
                </div>
            </div>
        }
    </div>
);

const ExtraDetails = props => (
    <span className="details">
        {
            props.person.gender &&
            <div className="extra-details gender">
                Gender: {props.person.gender}
            </div>
        }
        {
            props.person.notes &&
            <div className="extra-details notes">
                Notes: {props.person.notes}
            </div>
        }
    </span>
);

export {DateDetails, TimeDetails, ExtraDetails};
