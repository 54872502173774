import React, {Component} from 'react'
import withNavigate from '../components/withNavigate';

import GoogleAuthButton from '../components/Auth/Google';

class Login extends Component {
    render() {
        return (
            <div className="login">
                <h4>Login</h4>
                <GoogleAuthButton/>
            </div>
        );
    }
}

export default withNavigate(Login);
