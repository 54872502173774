import React, {Component} from 'react'

import './Home.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

class Home extends Component {
    state = {
        isLoggedIn: false
    };

    componentDidMount() {
        this.setState({
            isLoggedIn: localStorage.getItem('loggedUser') !== null
        })
    }

    render() {
        const {isLoggedIn} = this.state;

        return (
            <div className="home">

                <Container fluid className='bg-light p-5 rounded-3'>
                    <Row className='centered align-items-center' size='xxl'>
                        <Col xs={12}>
                            <h1>White Lotus</h1>
                            <p>
                                White Lotus is an application dedicated to those who want to
                                get a bit more understanding into chinese astrology and
                                numerology, but at the same time understand how it can be
                                used
                                to create a more balanced life.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <h2 className='centered'>BaZi</h2>
                            <p className='centered'>Chinese astrology relies on cycles to make its
                                predictions.
                                And even thou it has only 12 earthly branches (or signs)
                                and 10 heavenly stems (or phases), their combination provides
                                allows great complexity to be explained.</p>
                            <p className='centered'>
                                <Link className='dropdown-item'
                                      to={'/bazi-free-analysis'}>
                                    <Button size='lg' variant='dark'>Get a simple BaZi
                                        analysis</Button>
                                </Link>
                            </p>
                        </Col>
                        <Col xs={12} md={6}>
                            <h2 className='centered'>Numerology</h2>
                            <p className='centered'>The chosen numerology school is Pythagorean
                                numerology.
                                It uses a square where numbers are laid out and interpreted.
                                It provides complementary information to the BaZi algorithm.</p>
                            <p className='centered'>
                                <Link className='dropdown-item'
                                      to={'/ps-free-analysis'}>
                                    <Button size='lg' variant='dark'>Get a simple numerology
                                        analysis</Button>
                                </Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
                {!isLoggedIn &&
                <Container>
                    <Row className='jumbo centered'>
                        <Col xs={12} md={6}>
                            <h2 className='centered'>Members</h2>
                            <p className='centered'> Create an account to enjoy access to all
                                functionalities like view of the daily and monthly Bazi potential of
                                the day and ability to create and save people's Bazi and
                                Numerolagical analysis with all the insites</p>
                            <div className='centered'>
                                <Link className='dropdown-item'
                                      to={'/login'}>
                                    <Button size='lg' variant='dark'>Login</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                }
            </div>
        );
    }
}

export default Home;
