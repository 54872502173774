import React from 'react';
import './Contact.css'
import {withTranslation} from "react-i18next";

const Contact = () => {
    return (
        <div className="email-with-logo">
                <i className="fas fa-envelope"></i>
                <span className="email">cristian.cotoi@gmail.com</span>
        </div>
    );
}

export default withTranslation()(Contact);
