import initialState from './initialState';
import {FETCH_BAZI_BASE_ELEMENTS, RECEIVE_BAZI_BASE_ELEMENTS} from '../actions/actionTypes';

export default function baZiReducer(state = initialState.bazi, action) {
    let newState;
    switch (action.type) {
        case FETCH_BAZI_BASE_ELEMENTS:
            console.log('FETCH_BAZI_BASE_ELEMENTS Action');
            return action;
        case RECEIVE_BAZI_BASE_ELEMENTS:
            newState = action.baZiBaseElements;
            console.log('RECEIVE_BAZI_BASE_ELEMENTS Action');
            return newState;
        default:
            return state;
    }
}